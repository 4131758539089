function validateFieldLength(field, model, next, maxLength) {
  return model[field.key] ? next(model[field.key].length <= maxLength) : next(false);
}

const tokenFields = [
  {
    key: 'merchantId',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Merchant ID',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Merchant ID',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => validateFieldLength(field, model, next, 50),
        message: 'Merchant ID must not exceed 50 characters',
      },
    },
  },
  {
    key: 'interfaceStatus',
    type: 'select-with-field',
    templateOptions: {
      options: [
        {
          text: 'Sandbox',
          value: 'SANDBOX',
        },
        {
          text: 'Live',
          value: 'LIVE',
        },
      ],
      properties: {
        placeholder: 'Select an interface status',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Interface Status',
        },
      },
    },
  },
  {
    key: 'beneficiaryName',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Beneficiary Name',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Beneficiary Name',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => validateFieldLength(field, model, next, 250),
        message: 'Beneficiary Name must not exceed 250 characters.',
      },
    },
  },
  {
    key: 'accountNumber',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Account Number',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Account Number',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => next(model[field.key]?.length === 8),
        message: 'Account Number must be exactly 8 characters',
      },
      isNumber: {
        expression: (field, model, next) => next(/^\d+$/.test(model[field.key])),
        message: 'Account Number must contain only numbers',
      },
    },
  },
  {
    key: 'sortCode',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Sort Code',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Sort Code',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => next(model[field.key]?.length === 6),
        message: 'Sort Code must be exactly 6 characters',
      },
      isNumber: {
        expression: (field, model, next) => next(/^\d+$/.test(model[field.key])),
        message: 'Sort Code must contain only numbers',
      },
    },
  },
];

export default tokenFields;
