import baseFields from './baseFields';
import cardstreamFields from './cardstreamFields';
import wpmFields from './wpmFields';
import countryCodes from '../generic/countryCodes';
import currencyCodes from '../generic/currencyCodes';
import vyneFields from './vyneFields';
import westernUnionFields from './westernUnionFields';
import vynePartnerFields from './vynePartnerFields';
import tokenFields from './tokenFields';

export default {
  dynamicFieldName: 'paymentProvider',
  fieldValues: {
    CS: [baseFields, cardstreamFields, countryCodes, currencyCodes].reduce(
      (a, b) => a.concat(b),
      [],
    ),
    WPM: [baseFields, wpmFields].reduce((a, b) => a.concat(b), []),
    VYNE: [baseFields, vyneFields].reduce((a, b) => a.concat(b), []),
    WU: [baseFields, westernUnionFields].reduce((a, b) => a.concat(b), []),
    VYNE_PARTNER: [baseFields, vynePartnerFields].reduce((a, b) => a.concat(b), []),
    TOKEN: [baseFields, tokenFields].reduce((a, b) => a.concat(b), []),
  },
};
